import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { useIdleTimer } from 'react-idle-timer';

import { Sidebar } from './Sidebar/Sidebar';
import { CompleteDetailsDialog } from './CompleteDetailsDialog/CompleteDetailsDialog';

import { DesktopContainer, DesktopChildrenContainer, LayoutLoader } from './Layout.styles';

import { routes } from '@/helpers/routes';
import { isHadron } from '@/helpers/utils/isHadron';
import { resetMixpanel } from '@/helpers/services/mixPanel';
import { initServices } from '@/helpers/services/initServices';
import { handleGetTimeByEnv } from '@/helpers/utils/getTimeByEnv';
import { getAuthUrl, getCustomLogout } from '@/helpers/services/auth';
import {
  clearLocalStorage,
  getUserPreferences,
  getUserStorage,
  setStorageItem,
  storageKeys
} from '@/helpers/utils/localStorage';
import {
  EnvType,
  UserSchema,
  useRetrieveUserInfoApiV1UsersMeGet,
  useGetPentestConfigApiV1PentestConfigGet,
  useGetUiPreferencesApiV1UiPreferencesGet
} from '@/helpers/services/api';

interface LayoutProps {
  darkMode: boolean;
  children: ReactNode | ReactNode[];
  setDarkMode: (darkMode: boolean) => void;
}

export const Layout = ({ children, darkMode, setDarkMode }: LayoutProps) => {
  const { organization_name } = getUserStorage();
  const { lightBar, activeEnvs } = getUserPreferences();

  const [user, setUser] = useState<UserSchema>();
  const [iconsOnly, setIconsOnly] = useState(lightBar);

  const { pathname, replace, push } = useRouter();
  const { data: uiData } = useGetUiPreferencesApiV1UiPreferencesGet();
  const { data: pentestData } = useGetPentestConfigApiV1PentestConfigGet();
  const { data: userData, isFetched } = useRetrieveUserInfoApiV1UsersMeGet();

  const isPentestHidden = !pentestData?.pentest_enabled || isHadron();

  const isLicenseRoute = pathname.includes(routes.licenseAgreement);
  const noLicense = isFetched && !!userData?.email && (!userData?.eula || !userData.email_verified) && !isLicenseRoute;
  const noOrg = isFetched && !!userData?.email && !userData?.organization_name && !!userData.email_verified;

  useIdleTimer({
    onIdle: () => {
      getCustomLogout()?.then(() => {
        setUser(undefined);
        resetMixpanel();
        window?.location?.replace(getAuthUrl());
      });
    },
    timeout: handleGetTimeByEnv()
  });

  useEffect(() => {
    // Delete storage data for different org
    if (userData?.organization_name && organization_name && userData?.organization_name !== organization_name) {
      clearLocalStorage();
      replace({ pathname, query: {} });
    }

    // Set new storage data for different org
    const setNewStorageData = setTimeout(() => {
      setUser(userData as UserSchema);
      setStorageItem(storageKeys.user, {
        name: userData?.name,
        email: userData?.email,
        deploymentType: userData?.deployment_type,
        organization_name: userData?.organization_name
      });
      setStorageItem(storageKeys.userPreferences, {
        lightBar,
        darkMode,
        activeEnvs: isPentestHidden ? [EnvType['EVAL'], EnvType['PROD']] : uiData?.active_envs || activeEnvs
      });
    }, 800);

    return () => clearTimeout(setNewStorageData);
  }, [userData, userData?.email, uiData?.active_envs, isPentestHidden]);

  useEffect(() => {
    if (!isLicenseRoute && noLicense) {
      push(routes.licenseAgreement);
    }
  }, [user, isLicenseRoute, noLicense]);

  useEffect(() => {
    if (userData?.email) {
      initServices({ mail: userData?.email, name: `${userData?.name}` });
    }
  }, [userData?.email]);

  if (!user?.email && !isLicenseRoute) {
    return (
      <DesktopContainer type="bg">
        <LayoutLoader size={100} />
      </DesktopContainer>
    );
  } else {
    return (
      <DesktopContainer type="bg">
        <Sidebar
          user={user}
          setUser={setUser}
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          iconsOnly={iconsOnly}
          setIconsOnly={setIconsOnly}
        />
        <DesktopChildrenContainer type="bg">
          {React.cloneElement(children as ReactElement, { isMenuOpen: !iconsOnly } as { isMenuOpen: boolean })}
        </DesktopChildrenContainer>
        <CompleteDetailsDialog open={noOrg} />
      </DesktopContainer>
    );
  }
};
